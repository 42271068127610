import { BoxProps } from '@chakra-ui/react';

export type EntryCardVariant = 'default' | 'scroller';
type VariantStyles = Record<EntryCardVariant, BoxProps>;
type EntryCardParts =
  | 'container'
  | 'itemInfo'
  | 'stats'
  | 'title'
  | 'imageContainer'
  | 'imageItem'
  | 'highestBidderText';

const titleStyles: VariantStyles = {
  default: {
    wordBreak: { base: 'break-word' },
    noOfLines: 3,
    'aria-orientation': 'vertical',
  },
  scroller: {
    noOfLines: 3,
    'aria-orientation': 'vertical',
  },
};

const statsStyles: VariantStyles = {
  default: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  scroller: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
};

const itemInfoStyles: VariantStyles = {
  default: {
    padding: { base: '0 0 0 .8rem', md: 3 },
    width: { base: '60%', md: 'auto' },
    height: { base: '100%', md: 'auto' },
  },
  scroller: {
    padding: 3,
    width: 'auto',
  },
};

const containerStyles: VariantStyles = {
  default: {
    alignItems: { base: 'flex-start', md: 'unset' },
    flexGrow: 1,
    flexDirection: { base: 'row-reverse', md: 'column-reverse' },
    padding: { base: '1rem', md: 0 },
  },
  scroller: {
    height: { base: 356, lg: 400 },
    alignItems: 'unset',
    flexGrow: 1,
    flexDirection: 'column-reverse',
    padding: 0,
    borderRadius: 8,
    boxShadow: '0 2px 6px 0 rgb(0 0 0 / 20%)',
  },
};

const imageContainerStyles: VariantStyles = {
  default: { borderRadius: { base: '8px', md: '8px 8px 0 0' } },
  scroller: { borderRadius: '8px 8px 0 0' },
};

const imageItemStyles: VariantStyles = {
  default: {
    height: { base: 120, md: 163, lg: 213 },
    width: { base: 180, md: 'auto' },
  },
  scroller: {
    height: { base: 163, lg: 213 },
    width: 'auto',
  },
};

const highestBidderTextStyles: VariantStyles = {
  default: {
    margin: { base: 0, md: 2 },
    padding: { base: '4px 8px', md: '2px 8px' },
    boxShadow: { base: 0, md: '0px 1px 2px rgba(0, 0, 0, 0.2)' },
    borderRadius: { base: '0 0 8px 8px', md: 10 },
    position: { md: 'absolute' },
    top: { base: '100%', md: 0 },
    left: { base: 0, md: 'initial' },
  },
  scroller: {
    margin: 2,
    padding: '2px 8px',
    boxShadow: 0,
    borderRadius: 10,
    position: 'absolute',
    top: 0,
    left: 'initial',
  },
};

export const variantStyles: Record<EntryCardParts, VariantStyles> = {
  container: containerStyles,
  itemInfo: itemInfoStyles,
  stats: statsStyles,
  title: titleStyles,
  imageContainer: imageContainerStyles,
  imageItem: imageItemStyles,
  highestBidderText: highestBidderTextStyles,
};
