import { DeliveryMethod } from '../types/ListEntry';
import { ProcessingCostInfo } from 'types/ApiResponse';
import { PriceWithVat, PriceWithoutVat } from 'types/Price';

export function addVatToPrice(price: PriceWithoutVat, vatPerc: number): PriceWithVat {
  return {
    amount: Math.round(price.amount * (1 + vatPerc / 100)),
    vatPercent: vatPerc,
  };
}

export function priceWithVat(priceWithoutVat: number, vatPerc: number) {
  return Math.round(priceWithoutVat * (1 + vatPerc / 100));
}

export function vatAmount(price: PriceWithVat): PriceWithoutVat {
  return {
    amount: price.amount - price.amount / (1 + price.vatPercent / 100),
  };
}

export function priceWithoutVat(price: PriceWithVat): PriceWithoutVat {
  return {
    amount: price.amount - vatAmount(price).amount,
  };
}

const getMinimumDeliveryExpenses = (deliveryMethods: DeliveryMethod[]) =>
  deliveryMethods.length < 1
    ? 0
    : deliveryMethods.reduce(
        (min, method) => ((method.expenses ?? 0) < min ? (method.expenses ?? 0) : min),
        deliveryMethods[0].expenses ?? 0
      );

/**
 * Calculate total price with minimum delivery expenses.
 * @param highestBid Highest bid.
 * @param startPrice Highest bid.
 * @param minimumRaise If not null minimumRaise and highestBid summed.
 * @param vatPerc VAT percent.
 * @param deliveryMethods Array of DeliveryMethods to seek minimum possible expense.
 * @param processingCostInfo Possible processingCosts.
 * @param includeProcessingCosts Include/Exclude processingCosts expenses.
 * @returns total price combined with minimum expenses
 */
export function priceWithMinDeliveryExpenses(
  highestBid: number,
  startPrice: number,
  minimumRaise: number | null,
  vatPerc: number,
  deliveryMethods: DeliveryMethod[],
  processingCostInfo: ProcessingCostInfo | null,
  includeProcessingCosts = false
) {
  const bidWithVat = priceWithVat(highestBid + (minimumRaise ?? 0), vatPerc);
  const startPriceWithVat = priceWithVat(startPrice + (minimumRaise ?? 0), vatPerc);
  const totalSum = highestBid > 0 ? bidWithVat : startPriceWithVat;
  const minimumDeliveryExpenses = getMinimumDeliveryExpenses(deliveryMethods);
  const processingCosts = (processingCostInfo && processingCostInfo.sum) ?? 0;
  const combinedExpenses = minimumDeliveryExpenses + (includeProcessingCosts ? processingCosts : 0);
  return totalSum + combinedExpenses;
}
