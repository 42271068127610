'use client';

import { useTokens, Button, ButtonProps } from '@mezzoforte/forge';
import { Heart } from '@mezzoforte/forge-icons';
import { useFavorites } from 'hooks/useFavorites';
import { useFavoriteCount } from 'hooks/useFavoriteCount';
import { FavoriteNotLoggedInModal } from './FavoriteNotLoggedInModal';
import { useState } from 'react';
import { useLoginModal } from 'hooks/useLoginModal';
import { useSession } from 'hooks/useSession';
import { gtmService } from '../../util/gtm-service';

export interface ToggleFavoriteButtonProps extends ButtonProps {
  entryId: number;
  ecommerceItem: Gtag.Item;
  showFavoriteCount?: boolean;
}
const {
  colors,
  fonts,
  fontSizes,
  lineHeights,
  shadows: { box },
} = useTokens.huutokaupat;

export function ToggleFavoriteButton({
  entryId,
  size,
  ecommerceItem,
  showFavoriteCount = false,
  ...buttonProps
}: ToggleFavoriteButtonProps) {
  const { toggleFavorite, hasFavorite } = useFavorites();
  const isFavorite = hasFavorite(entryId);
  const [showFavoriteAddedModal, setShowFavoriteAddedModal] = useState<boolean>(false);
  const { LoginModal, openLoginModal } = useLoginModal();
  const { currentUser } = useSession();
  const loggedIn = currentUser.data?.isAuthenticated ?? false;
  const favoriteCount = useFavoriteCount(entryId, showFavoriteCount);

  const iconStates = new Map([
    [true, { weight: 'fill', color: colors.danger, stroke: colors.dangerDark } as const],
    [false, {} as const],
  ]);

  const onFavorite = () => {
    gtmService.recommendedEcommerce.addToWishlist(ecommerceItem);
  };

  return (
    <>
      <LoginModal />
      <FavoriteNotLoggedInModal
        isOpen={showFavoriteAddedModal}
        onDismiss={(showLoginModal: boolean) => {
          setShowFavoriteAddedModal(false);
          if (showLoginModal) {
            openLoginModal();
          }
        }}
      />

      <Button
        variant="default"
        boxShadow={box}
        color="text"
        border="none"
        position="relative"
        onClick={() => {
          toggleFavorite(entryId);

          if (!loggedIn) {
            setShowFavoriteAddedModal(!isFavorite);
          }

          if (!hasFavorite(entryId)) {
            onFavorite();
          }
        }}
        leftIcon={<Heart {...iconStates.get(isFavorite)} />}
        aria-label={isFavorite ? 'Poista seurantalistalta' : 'Lisää seurantalistalle'}
        title={isFavorite ? 'Poista seurantalistalta' : 'Lisää seurantalistalle'}
        minH={0}
        minW={0}
        _hover={{ '& path': { color: colors.dangerDark } }}
        _after={{
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '40px',
          height: '40px',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        }}
        sx={{
          fontFamily: fonts.body,
          fontSize: { base: fontSizes.supplementary, md: fontSizes.bodySmall },
          lineHeight: { base: lineHeights.supplementary, md: lineHeights.bodySmall },
          gap: favoriteCount > 0 ? { base: 0.5, md: 1 } : 0,
          p: { base: 1, md: size === 'sm' ? 1 : 2 },
          span: { marginInlineEnd: 0 },
          svg: { width: { base: '16px', md: size === 'sm' ? '16px' : '24px' }, height: 'auto' },
        }}
        {...buttonProps}
      >
        <span>{favoriteCount > 0 && favoriteCount}</span>
      </Button>
    </>
  );
}
