import { useQuery } from '@tanstack/react-query';
import { client } from 'util/apiClient';

const fetchFavoriteCount = async (entryId: number): Promise<number> => {
  const { data } = await client.get<{ count: number }>(`/api/net-auctions/${entryId}/favorite-count`);
  return data.count;
};

export const useFavoriteCount = (entryId: number, showFavoriteCount: boolean) => {
  const { data: favoriteCount } = useQuery({
    queryKey: ['favoriteCount', entryId],
    queryFn: () => fetchFavoriteCount(entryId),
    staleTime: 1000 * 60,
    enabled: showFavoriteCount && !!entryId,
  });

  return favoriteCount ?? 0;
};
