import { Box, Flex } from '@mezzoforte/forge';
import { Package } from '@mezzoforte/forge-icons';
import { CustomBadgeContent, CustomBadgeKey } from 'types/CustomBadge';

interface CustomBadgeProps {
  readonly badgeKey: CustomBadgeKey;
}

export const CustomBadges: Record<CustomBadgeKey, CustomBadgeContent> = {
  reduced_delivery: {
    text: 'Edullinen toimitus',
    icon: <Package weight="fill" size={14} />,
    background: 'backgroundWarning',
    color: 'warning',
  },
};

export const CustomBadge: React.FC<CustomBadgeProps> = ({ badgeKey }) => {
  const badge = CustomBadges[badgeKey];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      minHeight={{ base: '24px', sm: 'auto' }}
      gap={1}
      color={badge.color}
    >
      {badge.icon}
      <Box color="text">{badge.text}</Box>
    </Flex>
  );
};
