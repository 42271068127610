export const TimeLeftSlack = 5;

export const getTimeLeft = (auctionEnd: Date, now: Date, skew = 0, ignoreSlack = false) => {
  const timeLeft = auctionEnd.getTime() / 1000 - now.getTime() / 1000 + skew;

  return ignoreSlack ? timeLeft : timeLeft - TimeLeftSlack;
};

const getTimeLeftUnits = (timeLeft: number) => ({
  days: Math.floor((timeLeft % (365 * 24 * 3600)) / (24 * 3600)),
  hours: Math.floor((timeLeft % (24 * 3600)) / 3600),
  mins: Math.floor((timeLeft % 3600) / 60),
  secs: Math.floor(timeLeft % 60),
});

export const getTimeLeftAsText = (timeLeft: number) => {
  if (timeLeft <= 0) return '';

  const units = getTimeLeftUnits(timeLeft);

  const valuesAndLabels = [
    { value: units.days, text: `${units.days} pv` },
    { value: units.hours, text: `${units.hours} t` },
    { value: units.mins, text: `${units.mins} min` },
    { value: units.secs, text: `${units.secs} s` },
  ];

  const firstNonEmptyUnitIndex = valuesAndLabels.findIndex(valueAndLabel => valueAndLabel.value !== 0);

  return valuesAndLabels
    .slice(firstNonEmptyUnitIndex, Math.min(firstNonEmptyUnitIndex + 2, valuesAndLabels.length))
    .map(valueAndLabel => valueAndLabel.text)
    .join(' ');
};

export type EntryTimeLeftStatus = 'ongoing' | 'ended' | 'final-check' | 'cancelled' | 'continuing' | 'scheduled';

export function getAuctionStatus(
  isEnded: boolean,
  isContinuous: boolean,
  isCancelled: boolean,
  bidCount: number,
  auctionStart: Date,
  auctionEnd: Date,
  skew: number
): EntryTimeLeftStatus {
  if (isCancelled) return 'cancelled';
  if (isEnded && isContinuous && bidCount === 0) return 'continuing';
  if (isEnded) return 'ended';
  if (auctionStart > new Date()) return 'scheduled';

  if (getTimeLeft(auctionEnd, new Date(), skew) < 1) {
    return 'final-check';
  }

  return 'ongoing';
}
