import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useToast } from '@mezzoforte/forge';
import { client } from 'util/apiClient';
import { formatCurrency } from 'util/currency';
import { priceWithVat } from 'util/price';
import type { FavoriteList } from 'types/Favorite';
import type { FavoritesAPIResponse, SmsNotificationFailureAPIResponse } from 'types/ApiResponse';

const fetchFavorites = async (): Promise<FavoriteList> =>
  client.get<FavoritesAPIResponse>('/api/favorites').then(({ data }) =>
    // Map API Response object to FavoriteList interface
    Promise.resolve({
      success: data.success,
      bidderIds: new Map(Object.entries(data.bidderIds ?? {}).map(([a, b]) => [Number(a), b])),
      entries: data.entries.map(e => {
        const totalPriceAmount = e.bidCount
          ? priceWithVat(e.highestBid, e.vatPerc)
          : priceWithVat(e.startPrice, e.vatPerc);
        return {
          ...e,
          auctionStart: new Date(e.auctionStart),
          auctionEnd: new Date(e.auctionEnd),
          totalPrice: { amount: totalPriceAmount, vatPercent: e.vatPerc },
          totalPriceString: formatCurrency(totalPriceAmount),
        };
      }),
      entriesWithNotifications: new Set(data.entriesWithNotifications),
    })
  );

export const useFavorites = () => {
  const queryClient = useQueryClient();
  const favorites = useQuery({ queryKey: ['entries', 'favorites'], queryFn: fetchFavorites, staleTime: 1000 * 60 });
  const { playToast } = useToast();

  const addFavorite = useMutation({
    mutationFn: (entryId: number) =>
      client.post('/api/favorites', { entryId }).catch((reason: AxiosError) => {
        if (reason.response?.status === 404) return Promise.resolve({ success: true });
        return Promise.reject(reason);
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['entries', 'favorites'] });
      queryClient.invalidateQueries({ queryKey: ['favoriteCount'] });
    },
  });
  const removeFavorite = useMutation({
    mutationFn: (id: number) => client.delete(`/api/favorites/${id}`),
    onMutate: async id => {
      // Optimistic update
      await queryClient.cancelQueries({ queryKey: ['entries', 'favorites'] });
      const previousFavorites = queryClient.getQueryData<FavoriteList>(['entries', 'favorites']);
      queryClient.setQueryData<FavoriteList>(['entries', 'favorites'], old =>
        old ? { ...old, entries: old.entries.filter(favorite => favorite.id !== id) } : undefined
      );

      return { previousFavorites };
    },
    onError: (err: AxiosError, _id, context) => {
      // Favorites API returns a 404 error if item to be deleted is not in the list.
      // This is not an error: the item is not in the list, which is expected behaviour.
      // Do not roll back optimistic update in this case.
      if (err.response?.status !== 404) {
        queryClient.setQueryData(['entries', 'favorites'], context?.previousFavorites);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['entries', 'favorites'] });
      queryClient.invalidateQueries({ queryKey: ['favoriteCount'] });
    },
  });

  const toggleSmsNotification = useMutation({
    mutationFn: (entryId: number) =>
      client.patch(`/api/favorites/${entryId}`, { notify: !hasSmsNotification(entryId) }).catch(reason => {
        console.log('catch', reason);
        if (reason.response?.status === 404) return Promise.resolve({ success: true });
        return Promise.reject(reason);
      }),
    onError: (error: AxiosError<SmsNotificationFailureAPIResponse>) => {
      if (error.response?.data.message === 'Invalid phone number') {
        playToast('Ilmoitusten asettaminen epäonnistui', 'Tarkista puhelinnumero', { variant: 'danger' });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['entries', 'favorites'] });
    },
  });

  const hasFavorite = (id: number) => (favorites.data?.entries ?? []).find(f => f.id === id) !== undefined;
  const hasSmsNotification = (id: number) => favorites.data?.entriesWithNotifications.has(id);

  const toggleFavorite = (id: number) => {
    if (hasFavorite(id)) {
      return removeFavorite.mutate(id);
    } else {
      return addFavorite.mutate(id);
    }
  };

  return {
    favorites,
    addFavorite,
    removeFavorite,
    hasFavorite,
    hasSmsNotification,
    toggleFavorite,
    toggleSmsNotification,
  };
};
